import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../layout/layout";
import ProjectLogo from "../components/projectlogo";
import SEO from "../components/seo";

export const query = graphql`
    query HomeQuery {
        sanityHomepage(id: { eq: "-9b79f4ec-9d25-50fa-8011-e10d7c705774" }) {
            page_title
            homeContent {
                children {
                    text
                    _key
                    _type
                    marks
                }
            }
            signposts {
                buttonLink
                buttonTitle
                _key
                _type
            }
        }
    }
`;

const Home = ({ data }) => {
    const homedata = data.sanityHomepage;

    useEffect(() => {
        if (homedata.page_title === "Homepage") {
            document.body.classList.add("home");
        }
    });
    return (
        <Layout>
            <SEO
                title="Project Change | Climate Workshops | UEA"
                canonical="https://projectchange.uea.ac.uk"
            />
            <main id="home" className="home p-6">
                <div className="container mx-auto text-center">
                    <div className="home-logo w-full sm:w-2/3 mx-auto pt-32 pb-16">
                        <ProjectLogo />
                    </div>
                </div>
                <div className="container mx-auto flex flex-wrap mb-16">
                    <div className="w-full sm:w-1/2 mx-auto">
                        {homedata.homeContent.map((block) =>
                            block.children.map((content, i) =>
                                content.text ? (
                                    <p className="text-center" key={i}>
                                        {content.text}
                                    </p>
                                ) : (
                                    ""
                                )
                            )
                        )}
                    </div>
                </div>
                <div className="container mx-auto flex flex-wrap">
                    <div className="signposts w-full sm:w-5/6 md:w-2/3 mx-auto text-center">
                        {homedata.signposts.map((signpost) => (
                            <Link to={signpost.buttonLink} className="button">
                                {signpost.buttonTitle}
                            </Link>
                        ))}
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default Home;
